import axios from "axios";
import moment from "moment-timezone";
import { store } from "../redux/store";
import {
  updateModemAuth,
  updateLoginHistory,
  updateInternetUsageData,
  InternetUsageType,
  updateOpenOutageData,
  updateClosedOutageData,
} from "../redux/serviceSlice";
import { showProgressBar } from "../redux/globalSlice";
import {
  clearOutageStatus,
  updateUnplannedOutage,
  updatePlannedOutage,
  updateInEffectOutage,
  updateFutureOutage,
  updateModemAuthNotice,
  updateLoginHistoryStatus,
  updateInternetUsageStatus,
} from "../redux/toolsetSlice";
import { TPIIApis } from "../constants/defaultConfig";
import { convertToNZTime } from "./FormatHelper";

export const removeLFC = (input: string): string => {
  return input.replace("LFC", "").trim();
};

// eslint-disable-next-line
export const setChorusOutageStatus = (data: any): void => {
  const currentDateTime = moment().tz("Pacific/Auckland");

  // eslint-disable-next-line
  data.services.forEach((service: any) => {
    const startTime = convertToNZTime(service.eventDetails.startTime);
    const endTime = convertToNZTime(service.eventDetails.endTime);
    const { eventType } = service.eventDetails;
    const { status } = service.eventDetails;

    // unplanned
    if (eventType === "Unplanned") {
      // open
      if (
        (status === "Updated" || status === "New") &&
        currentDateTime.isAfter(startTime)
      ) {
        store.dispatch(
          updateUnplannedOutage({
            outageLight: "red",
            outageTitle: "Unplanned Outage Reported",
            outageNotice: "Unplanned outage has been reported and is in effect",
          })
        );
      }

      // close
      if (
        (status === "Completed" ||
          status === "Cancelled" ||
          status === "Cleared" ||
          status === "History") &&
        currentDateTime.isAfter(endTime)
      ) {
        const timeDifference = currentDateTime.diff(endTime, "hours");
        if (timeDifference <= 24) {
          store.dispatch(
            updateUnplannedOutage({
              outageLight: "amber",
              outageTitle: "Unplanned Outage - last 24 hours (Resolved)",
              outageNotice:
                "Unplanned outage has occurred recently and is resolved",
            })
          );
        } else if (timeDifference <= 168) {
          store.dispatch(
            updateUnplannedOutage({
              outageLight: "green",
              outageTitle: "Unplanned Outage - last 7 days (Resolved)",
              outageNotice:
                "Unplanned outage has occurred in last 7 days and is resolved",
            })
          );
        }
      }
    }

    // planned
    if (eventType === "Planned") {
      // open
      if (status === "Updated" || status === "New") {
        if (currentDateTime.isAfter(startTime)) {
          store.dispatch(
            updateInEffectOutage({
              outageLight: "red",
              outageTitle: "Planned Outage Reported",
              outageNotice: "Planned outage has been reported and is in effect",
            })
          );
        }
        if (currentDateTime.isBefore(startTime)) {
          const timeDifference = startTime.diff(currentDateTime, "hours");
          if (timeDifference <= 24) {
            store.dispatch(
              updateFutureOutage({
                outageLight: "amber",
                outageTitle: "Planned Outage - next 24 hours",
                outageNotice:
                  "Planned outage is scheduled to occur in next 24 hours",
              })
            );
          } else if (timeDifference <= 168) {
            store.dispatch(
              updateFutureOutage({
                outageLight: "green",
                outageTitle: "Planned Outage - next 7 days",
                outageNotice:
                  "Planned outage is scheduled to occur in next 7 days",
              })
            );
          } else {
            store.dispatch(
              updateFutureOutage({
                outageLight: "green",
                outageTitle: "No Outage Reported",
                outageNotice: "No Outage Reported",
              })
            );
          }
        }
      }

      // closed
      if (
        (status === "Completed" ||
          status === "Cancelled" ||
          status === "Cleared" ||
          status === "History") &&
        currentDateTime.isAfter(endTime)
      ) {
        const timeDifference = currentDateTime.diff(endTime, "hours");

        if (timeDifference <= 24) {
          store.dispatch(
            updatePlannedOutage({
              outageLight: "amber",
              outageTitle: "Planned Outage - last 24 hours (Resolved)",
              outageNotice:
                "Planned outage has occurred recently and is resolved",
            })
          );
        } else if (timeDifference <= 168) {
          store.dispatch(
            updatePlannedOutage({
              outageLight: "green",
              outageTitle: "Planned Outage - last 7 days (Resolved)",
              outageNotice:
                "Planned outage has occurred in the last 7 days and is resolved",
            })
          );
        } else if (store.getState().toolset.plannedOutage.outageLight === "") {
          store.dispatch(
            updatePlannedOutage({
              outageLight: "green",
              outageTitle: "No Outage Reported",
              outageNotice: "No outage reported",
            })
          );
        }
      }
    }
  });
};

// eslint-disable-next-line
export const setTFFOutageStatus = (data: any): void => {
  const currentDateTime = moment().tz("Pacific/Auckland");

  // eslint-disable-next-line
  data.services.forEach((service: any) => {
    const startTime = convertToNZTime(service.eventDetails.startTime);
    const endTime = convertToNZTime(service.eventDetails.endTime);
    const { eventType } = service.eventDetails;
    const { status } = service.eventDetails;

    // unplanned
    if (eventType === "NetworkIncident") {
      // open
      if (status === "In Progress" && currentDateTime.isAfter(startTime)) {
        store.dispatch(
          updateUnplannedOutage({
            outageLight: "red",
            outageTitle: "Unplanned Outage Reported",
            outageNotice: "Unplanned outage has been reported and is in effect",
          })
        );
      }

      // close
      if (
        (status === "Closed" ||
          status === "Resolved" ||
          status === "Cancelled" ||
          status === "On Hold") &&
        currentDateTime.isAfter(endTime)
      ) {
        const timeDifference = currentDateTime.diff(endTime, "hours");
        if (timeDifference <= 24) {
          store.dispatch(
            updateUnplannedOutage({
              outageLight: "amber",
              outageTitle: "Unplanned Outage - last 24 hours (Resolved)",
              outageNotice:
                "Unplanned outage has occurred recently and is resolved",
            })
          );
        } else if (timeDifference <= 168) {
          store.dispatch(
            updateUnplannedOutage({
              outageLight: "green",
              outageTitle: "Unplanned Outage - last 7 days (Resolved)",
              outageNotice:
                "Unplanned outage has occurred in last 7 days and is resolved",
            })
          );
        } else {
          store.dispatch(
            updateFutureOutage({
              outageLight: "green",
              outageTitle: "No Outage Reported",
              outageNotice: "No Outage Reported",
            })
          );
        }
      }
    }

    // planned
    if (eventType === "ChangeRequest") {
      // open
      if (status === "Scheduled" || status === "Implement") {
        if (currentDateTime.isAfter(startTime)) {
          store.dispatch(
            updateInEffectOutage({
              outageLight: "red",
              outageTitle: "Planned Outage Reported",
              outageNotice: "Planned outage has been reported and is in effect",
            })
          );
        }
        if (currentDateTime.isBefore(startTime)) {
          const timeDifference = startTime.diff(currentDateTime, "hours");
          if (timeDifference <= 24) {
            store.dispatch(
              updateFutureOutage({
                outageLight: "amber",
                outageTitle: "Planned Outage - next 24 hours",
                outageNotice:
                  "Planned outage is scheduled to occur in next 24 hours",
              })
            );
          } else if (timeDifference <= 168) {
            store.dispatch(
              updateFutureOutage({
                outageLight: "green",
                outageTitle: "Planned Outage - next 7 days",
                outageNotice:
                  "Planned outage is scheduled to occur in next 7 days",
              })
            );
          } else {
            store.dispatch(
              updateFutureOutage({
                outageLight: "green",
                outageTitle: "No Outage Reported",
                outageNotice: "No Outage Reported",
              })
            );
          }
        }
      }

      // closed
      if (
        (status === "Closed" || status === "Resolved") &&
        currentDateTime.isAfter(endTime)
      ) {
        const timeDifference = currentDateTime.diff(endTime, "hours");

        if (timeDifference <= 24) {
          store.dispatch(
            updatePlannedOutage({
              outageLight: "amber",
              outageTitle: "Planned Outage - last 24 hours (Resolved)",
              outageNotice:
                "Planned outage has occurred recently and is resolved",
            })
          );
        } else if (timeDifference <= 168) {
          store.dispatch(
            updatePlannedOutage({
              outageLight: "green",
              outageTitle: "Planned Outage - last 7 days (Resolved)",
              outageNotice:
                "Planned outage has occurred in the last 7 days and is resolved",
            })
          );
        } else if (store.getState().toolset.plannedOutage.outageLight === "") {
          store.dispatch(
            updatePlannedOutage({
              outageLight: "green",
              outageTitle: "No Outage Reported",
              outageNotice: "No outage reported",
            })
          );
        }
      }
    }
  });
};

// eslint-disable-next-line
export const groupChorusOutageData = (data: any): void => {
  // eslint-disable-next-line
  const groupedData = data.services.reduce((result: any, service: any) => {
    const { status } = service.eventDetails;
    const updatedResult = { ...result };
    if (status === "Updated" || status === "New") {
      if (!result.open) {
        updatedResult.open = [];
      }
      updatedResult.open.push(service);
    } else if (
      status === "Cleared" ||
      status === "Completed" ||
      status === "Cleared" ||
      status === "History"
    ) {
      if (!result.closed) {
        updatedResult.closed = [];
      }
      updatedResult.closed.push(service);
    }
    return updatedResult;
  }, {});

  if (groupedData.open && groupedData.open.length > 0) {
    store.dispatch(updateOpenOutageData({ ...groupedData.open }));
  }

  if (groupedData.closed && groupedData.closed.length > 0) {
    store.dispatch(updateClosedOutageData({ ...groupedData.closed }));
  }
};

// eslint-disable-next-line
export const groupTFFOutageData = (data: any): void => {
  // eslint-disable-next-line
  const groupedData = data.services.reduce((result: any, service: any) => {
    const { status } = service.eventDetails;
    const updatedResult = { ...result };
    if (
      status === "In Progress" ||
      status === "Scheduled" ||
      status === "Implement"
    ) {
      if (!result.open) {
        updatedResult.open = [];
      }
      updatedResult.open.push(service);
    } else if (
      status === "Closed" ||
      status === "Resolved" ||
      status === "Cancelled" ||
      status === "On Hold"
    ) {
      if (!result.closed) {
        updatedResult.closed = [];
      }
      updatedResult.closed.push(service);
    }
    return updatedResult;
  }, {});

  if (groupedData.open && groupedData.open.length > 0) {
    store.dispatch(updateOpenOutageData({ ...groupedData.open }));
  }

  if (groupedData.closed && groupedData.closed.length > 0) {
    store.dispatch(updateClosedOutageData({ ...groupedData.closed }));
  }
};

export const getOutages = (asid: string, LFC: string): void => {
  store.dispatch(updateOpenOutageData([]));
  store.dispatch(updateClosedOutageData([]));
  store.dispatch(clearOutageStatus());
  if (asid) {
    const { login, token } = store.getState().login;
    axios
      .get(`${login.outageApi}/networkevents/services/${asid}`, {
        headers: { Authorization: token.idToken },
      })
      .then((response) => {
        if (LFC === "Chorus") {
          setChorusOutageStatus(response.data);
          groupChorusOutageData(response.data);
        }
        if (LFC === "TFF") {
          setTFFOutageStatus(response.data);
          groupTFFOutageData(response.data);
        }
      })
      .catch((error) => {
        if (error.response.data.message === "No Data Found") {
          store.dispatch(
            updateUnplannedOutage({
              outageLight: "green",
              outageTitle: "No Outage Reported",
              outageNotice: "No outage reported",
            })
          );
        } else {
          store.dispatch(
            updateFutureOutage({
              outageLight: "amber",
              outageTitle: "Unknown",
              outageNotice: "Unknown",
            })
          );
          console.error("Error in getOutages:", error);
        }
      });
  } else {
    store.dispatch(
      updateUnplannedOutage({
        outageLight: "red",
        outageTitle: "No asid found",
        outageNotice: "",
      })
    );
  }
};

export const getModemAuth = (
  cartridgeNumber: string,
  userName: string
): void => {
  store.dispatch(
    showProgressBar({
      isShown: true,
      pbMessage: "Checking modem...",
    })
  );
  const modemAuth = {
    username: userName,
    isAuth: false,
    connectionTime: "",
    IPAddress: "",
    serverIPAddresss: "",
  };
  const { login, token } = store.getState().login;
  const apiPath = login.useTPIIProxy
    ? `${login.oneAssistApi}/tpii/customer/${cartridgeNumber}/service/internet/${userName}/radius_user_online`
    : `${TPIIApis.getTPIICustomerData}${cartridgeNumber}/service/internet/${userName}/radius_user_online`;

  const config = login.useTPIIProxy
    ? { headers: { Authorization: token.idToken } }
    : {};
  axios
    .get(apiPath, config)
    .then((response) => {
      if (response.data.status === "Ok") {
        if (
          Object.keys(response.data.data.radius_users_online.currentSession[0])
            .length !== 0
        ) {
          modemAuth.connectionTime =
            response.data.data.radius_users_online.currentSession[0].acctstarttime;
          modemAuth.IPAddress =
            response.data.data.radius_users_online.currentSession[0].framedipaddress;
          modemAuth.serverIPAddresss =
            response.data.data.radius_users_online.currentSession[0].nasipaddress;
          modemAuth.isAuth = true;
          store.dispatch(updateModemAuthNotice("Online"));
        } else {
          modemAuth.isAuth = false;
          store.dispatch(updateModemAuthNotice("Offline"));
        }
      }
      store.dispatch(updateModemAuth(modemAuth));
      store.dispatch(
        showProgressBar({
          isShown: false,
          pbMessage: "",
        })
      );
    })
    .catch(() => {
      store.dispatch(updateModemAuthNotice("Error"));
      modemAuth.isAuth = false;
      store.dispatch(updateModemAuth(modemAuth));
      store.dispatch(
        showProgressBar({
          isShown: false,
          pbMessage: "",
        })
      );
    });
};

export const getLoginHistory = (): void => {
  store.dispatch(updateLoginHistory([])); // clear first
  const { service, username } = store.getState().service;
  if (service.samId && username) {
    const { login, token } = store.getState().login;
    const apiPath = login.useTPIIProxy
      ? `${login.oneAssistApi}/tpii/customer/${service.samId}/service/internet/${username}/login_history`
      : `${TPIIApis.getTPIICustomerData}${service.samId}/service/internet/${username}/login_history`;

    const config = login.useTPIIProxy
      ? { headers: { Authorization: token.idToken } }
      : {};
    axios
      .get(apiPath, config)
      .then((response) => {
        if (response.data.status === "Ok") {
          store.dispatch(updateLoginHistory(response.data.data.login_history));
          store.dispatch(
            updateLoginHistoryStatus({
              isGetLoginHistory: true,
              loginNotice: "Displayed",
            })
          );
        } else {
          store.dispatch(
            updateLoginHistoryStatus({
              isGetLoginHistory: false,
              loginNotice: "No Login History found for this service",
            })
          );
        }
      })
      .catch(() => {
        store.dispatch(
          updateLoginHistoryStatus({
            isGetLoginHistory: false,
            loginNotice: "No Login History found for this service",
          })
        );
      });
  } else {
    store.dispatch(
      updateLoginHistoryStatus({
        isGetLoginHistory: false,
        loginNotice: "No samId or username found",
      })
    );
  }
};

export const removeZeros = (lanNumber: string): string => {
  if (lanNumber) {
    const regex = new RegExp("^0+(?!$)", "g");
    return lanNumber.replace(regex, "");
  }
  return lanNumber;
};

export const getInternetUsage = (cartidge: string, username: string): void => {
  store.dispatch(updateInternetUsageData([])); // clear first
  const { login, token } = store.getState().login;
  const apiPath = login.useTPIIProxy
    ? `${login.oneAssistApi}/tpii/customer/${cartidge}/service/internet/${username}/usage`
    : `${TPIIApis.getTPIICustomerData}${cartidge}/service/internet/${username}/usage`;

  const config = login.useTPIIProxy
    ? { headers: { Authorization: token.idToken } }
    : {};
  axios
    .get(apiPath, config)
    .then((response) => {
      if (response.data.status === "Ok") {
        store.dispatch(
          updateInternetUsageData(response.data.data.internet_usage)
        );
        store.dispatch(
          updateInternetUsageStatus({
            isGetInternetUsage: true,
            notice: "Displayed",
          })
        );
      } else {
        store.dispatch(
          updateInternetUsageStatus({
            isGetInternetUsage: false,
            notice: "No Internet Usage found for this service",
          })
        );
      }
    })
    .catch(() => {
      store.dispatch(
        updateInternetUsageStatus({
          isGetInternetUsage: false,
          notice: "No Internet Usage found for this service",
        })
      );
    });
};

export type NewInternetUsageType = {
  YEAR: string;
  MONTH: string;
  TRAFFIC_TYPE: string;
  LOGIN: string;
  TIME_GROUP: string;
  TRAFFIC_UNIT: string;
  DAY: string;
  INBOUND?: string;
  OUTBOUND?: string;
};

export const combineInternetUsageData = (
  data: InternetUsageType[]
): NewInternetUsageType[] => {
  const result: NewInternetUsageType[] = [];

  data.forEach((item) => {
    const existingItem = result.find((r) => {
      return (
        r.YEAR === item.YEAR &&
        r.MONTH === item.MONTH &&
        r.TRAFFIC_TYPE === item.TRAFFIC_TYPE &&
        r.LOGIN === item.LOGIN &&
        r.TIME_GROUP === item.TIME_GROUP &&
        r.TRAFFIC_UNIT === item.TRAFFIC_UNIT &&
        r.DAY === item.DAY
      );
    });

    if (existingItem) {
      if (item.TRAFFIC_DIRECTION === "Inbound") {
        existingItem.INBOUND = parseInt(item.TRAFFIC, 10).toString();
      } else {
        existingItem.OUTBOUND = parseInt(item.TRAFFIC, 10).toString();
      }
    } else {
      const newItem: NewInternetUsageType = {
        YEAR: item.YEAR,
        MONTH: item.MONTH,
        TRAFFIC_TYPE: item.TRAFFIC_TYPE,
        LOGIN: item.LOGIN,
        TIME_GROUP: item.TIME_GROUP,
        TRAFFIC_UNIT: item.TRAFFIC_UNIT,
        DAY: item.DAY,
      };

      if (item.TRAFFIC_DIRECTION === "Inbound") {
        newItem.INBOUND = parseInt(item.TRAFFIC, 10).toString();
      } else {
        newItem.OUTBOUND = parseInt(item.TRAFFIC, 10).toString();
      }

      result.push(newItem);
    }
  });

  return result;
};

export const converToMonthName = (monthNumber: string): string => {
  const monthName = new Date(
    Date.UTC(2000, parseInt(monthNumber, 10) - 1, 1)
  ).toLocaleString("en-US", { month: "long" });
  return monthName;
};

type TrafficDirection = "INBOUND" | "OUTBOUND";

export const sumUsageData = (
  data: NewInternetUsageType[],
  trafficDirection: TrafficDirection
): number => {
  const totalUsage = data.reduce((acc, cur) => {
    const usageValue = cur[trafficDirection];
    if (usageValue !== undefined && usageValue !== null) {
      const traffic = parseFloat(usageValue);
      return Number.isNaN(traffic) ? acc : acc + traffic;
    }
    return acc;
  }, 0);
  return totalUsage;
};
